
import SidebarLinkGroup from "./SidebarLinkGroup.vue"
import SidebarLinkItem from "./SidebarLinkItem.vue"
import SelectDropdown from "../other/SelectDropdown.vue"
import { mapState, mapActions } from "vuex"
import { serverURL, http } from "@/utils/http"
import { FILTER_TYPES } from "@/utils/common"

export default {
    computed: {
        ...mapState("auth", ["authenticated"]),
        ...mapState("auth", ["user", "isAdmin", "isSubAdmin"]),
        ...mapState("project", [
            "filterValues",
            "companyInitialized",
            "activeProject",
            "activeCompany",
            "issueStatuses",
            "activeCompanyProjects",
            "allProjects",
            "initIssues",
            "companies",
        ]),
    },
    components: { SidebarLinkGroup, SidebarLinkItem, SelectDropdown },
    data() {
        return {
            navOpen: false,
            showSelect: false,
            userData: {},
            serverURL,
        }
    },
    mounted() {
        this.fetchUser()
        this.refreshSelect()
    },
    methods: {
        ...mapActions("auth", ["logout"]),
        ...mapActions("project", ["clearActiveProject", "clearActiveCompany", "updateFilters"]),
        async setStatusFilter(valueId?: any) {
            await this.updateFilters({ type: FILTER_TYPES.STATUS, value: valueId ? [valueId] : [] })
        },
        isIssueStatusActive(issueStatus?: any) {
            const options = this.filterValues.status.selectedOptions

            const allItemsDisabled = this.issueStatuses.every((item: any) => {
                return !options.includes(item._id)
            })

            if (!issueStatus && options && options.length == 0) return allItemsDisabled

            if (issueStatus) return options.includes(issueStatus._id)
            return false
        },
        async fetchUser() {
            try {
                const res = await http.get("/users/" + this.user._id)
                this.userData = res.data.result
            } catch (err) {
                console.log("error in fetching userData", err)
            }
        },
        async clearActiveData() {
            await this.clearActiveProject()
            await this.clearActiveCompany()
            this.refreshSelect()
        },
        refreshSelect() {
            this.showSelect = false
            setTimeout(() => {
                this.showSelect = true
            }, 100)
        },
        async handleProjectChange(projectId: any) {
            const foundProject = this.activeCompanyProjects.find((item: any) => item._id === projectId)
            if (foundProject) {
                this.$router.push(`/projects/${foundProject.slug}`)
            } else if (this.activeCompany) {
                this.$router.push(`/companies/${this.activeCompany.slug}/projects`)
                await this.clearActiveProject()
            }
        },

        async handleCompanyChange(companyId: any) {
            const foundCompany = this.companies.find((item: any) => item._id === companyId)
            if (foundCompany) {
                this.$router.push(`/companies/${foundCompany.slug}/projects`)
            } else {
                this.$router.push(`/dashboard`)
                this.clearActiveData()
            }
        },
        logoutUtil() {
            this.logout()
            window.location.href = "/login"
        },
    },
}
