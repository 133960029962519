<template>
    <div>
        <PageWrapper :showHeaderSearch="false" title="Companies" :breadCrumbs="[{ title: 'Tasks', active: true }]">
            <template #header-options> </template>
            <template>
                <div class="mt-3">
                    <CrudIndex
                        v-if="!loading"
                        title="Company"
                        :viewColumns="columns"
                        fetchUrl="/companies"
                        createUrl="/companies"
                        updateRootUrl="/companies"
                        deleteRootUrl="/companies"
                        :createFields="createFields"
                        :editingFormatter="editingFormatter"
                    />
                </div>
            </template>
        </PageWrapper>
    </div>
</template>

<script>
import Lists from "@/components/Lists/Lists.vue"
import PageWrapper from "@/components/layout/PageWrapper.vue"
import CrudIndex from "@/components/crud/index"
import { http } from "@/utils/http"
import { createField, createColumn } from "@/components/crud/utils"
import HeaderSearch from "../components/UI/HeaderSearch.vue"

export default {
    components: { HeaderSearch, Lists, PageWrapper, CrudIndex },
    data() {
        return {
            loading: false,
            columns: [
                createColumn("Id", "index", "string"),
                createColumn("Name", "name", "string"),
                createColumn("Created At", "createdAtFormatted2", "string"),
            ],
            createFields: [],
        }
    },
    async mounted() {
        const users = await this.fetchUserIds()
        this.createFields = [
            createField("Name*", "name", "text", true),
            createField("Description", "description", "textarea", false),
            createField("Button Color", "btnColor", "color", false),
            createField("Thumbnail", "thumbnail", "image"),
            createField(
                "User Ids*",
                "userIds",
                "multiselect",
                true,
                "",
                users.map((item) => ({
                    _id: item._id,
                    title: item.firstName,
                }))
            ),
        ]
        this.loading = false
    },
    methods: {
        editingFormatter(item) {
            return {
                ...item,
            }
        },
        async fetchUserIds() {
            let result = []
            try {
                const res = await http.get("/users")
                result = res.data.result
            } catch (err) {
                console.log("error in loading customers", err)
            }
            this.initialized = true
            return result
        },
    },
}
</script>

<style lang="scss" scoped>
.card-text {
    font-size: 20px;
    font-weight: 600;
}
.issue-toggle-btn {
    padding: 10px;
    margin-left: 15px;
    border-radius: 5px;
    font-size: 0.9em;
    cursor: pointer;
    background-color: #f8f8f8;
    &:hover {
        background-color: #eee;
    }
}
</style>
