var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"pr-6 py-8 pl-10 h-full w-full flex flex-col"},[(_vm.showHeaderSearch)?_c('div',{class:[
                    'd-flex align-items-center',
                    { 'justify-content-between': _vm.showTitleOnHead },
                    { 'justify-content-end': !_vm.showTitleOnHead },
                ],staticStyle:{"gap":"15px"}},[(_vm.showTitleOnHead)?_c('div',[_c('div',{staticClass:"text-2xl font-medium"},[_vm._v(_vm._s(_vm.title))])]):_vm._e(),_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"15px"}},[_c('HeaderSearch',{on:{"change":function($event){return _vm.$emit('search', $event)}}}),_c('router-link',{attrs:{"to":"/profile"}},[_c('img',{staticClass:"shadow-sm",staticStyle:{"width":"35px","height":"35px","border-radius":"50%"},attrs:{"src":!!_vm.user.profilePic && !_vm.user.profilePic.includes('not-found')
                                    ? _vm.serverURL + _vm.user.profilePic
                                    : require('@/assets/user-icon.jpg')}})])],1)]):_vm._e(),(_vm.breadCrumbs.length)?_c('nav',{attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb mb-1"},_vm._l((_vm.breadCrumbs),function(breadCrumb){return _c('li',{key:breadCrumb.link,class:['breadcrumb-item', { active: breadCrumb.active }]},[_c('a',{attrs:{"href":breadCrumb.link || '#'}},[_vm._v(_vm._s(breadCrumb.title))])])}),0)]):_vm._e(),(!_vm.showTitleOnHead && _vm.title)?_c('header',{staticClass:"mt-1 d-flex justify-between text-textDarkest"},[_c('div',{staticClass:"d-flex",staticStyle:{"gap":"25px"}},[_c('div',{staticClass:"text-2xl font-medium"},[_vm._v(_vm._s(_vm.title))]),_vm._t("header-options-left")],2),_c('div',{staticClass:"d-flex"},[_vm._t("header-options")],2)]):_vm._e(),_c('div',{staticClass:"my-3"},[_vm._t("default")],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }