<template>
    <span v-if="user" @mouseover="startFetch" @mouseout="cancelFetch" class="user-hover-link-container">
        <a href="#" class="user-hover-link">{{ userFullName }}</a>
        <div class="hover-modal">
            <div class="hover-modal-content">
                <div class="d-flex mb-1 justify-content-between">
                    <div><strong>User Info</strong></div>
                    <hr />
                    <!-- <button @click="showDetails = false"><i class="fas fa-times" /></button> -->
                </div>
                <div>
                    <div class="text-center my-3" v-if="loading">
                        <span class="spinner-border spinner-border-sm" />
                    </div>
                    <div v-else-if="userInfo" class="d-flex">
                        <div>
                            <img
                                :src="
                                    !!userInfo.profilePic && !userInfo.profilePic.includes('not-found')
                                        ? serverURL + userInfo.profilePic
                                        : require('@/assets/user-icon.jpg')
                                "
                                style="width: 40px; height: 40px; object-fit: cover; border-radius: 50%"
                                class="mr-3 bg-white"
                                alt=""
                            />
                        </div>
                        <div>
                            <div>Full Name: {{ userInfoFullName }}</div>
                            <div>Position: {{ userInfo.positionName || "NA" }}</div>
                            <div>Email: {{ userInfo.email }}</div>
                            <div>Mobile: {{ userInfo.mobile }}</div>
                            <div>Experience: {{ userInfo.experienceInYears || "NA" }}</div>
                            <div>
                                Skills:
                                {{
                                    userInfo.skills.length > 0
                                        ? userInfo.skills.map((item) => `${item.skillId.name} (${item.percentage}%)`).join(", ")
                                        : "Not Added"
                                }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </span>
</template>

<script>
import { http, serverURL } from "@/utils/http"

export default {
    data() {
        return {
            loading: false,
            userInfo: null,
            fetchTimer: null,
            serverURL,
        }
    },
    props: {
        user: {
            type: Object,
            default: null,
        },
    },
    computed: {
        userFullName() {
            return this.getUserFullname(this.user)
        },
        userInfoFullName() {
            return this.getUserFullname(this.userInfo, true)
        },
    },
    methods: {
        startFetch() {
            clearTimeout(this.fetchTimer)
            this.fetchTimer = setTimeout(() => this.fetchUserDetails(), 500)
        },
        cancelFetch() {
            clearTimeout(this.fetchTimer)
        },
        getUserFullname(user, fullName = false) {
            return user ? `${user.firstName || ""} ${fullName ? user.lastName || "" : (user.lastName && user.lastName[0]) || ""}` : ""
        },
        async fetchUserDetails() {
            if (this.userInfo) return
            this.loading = true
            try {
                const res = await http.get(`/users/${this.user._id}`)
                this.userInfo = res.data.result
            } catch (err) {
                console.log("error in fetching user details", err)
            }
            this.loading = false
        },
    },
}
</script>

<style lang="scss" scoped>
.user-hover-link-container {
    position: relative;
}
.user-hover-link:hover {
    text-decoration: underline;
}

.user-hover-link-container:hover .hover-modal {
    display: block;
}
.hover-modal {
    display: none;
    z-index: 100;
    position: absolute;
    bottom: 25px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
    padding: 10px;
    width: 300px;
    min-height: 100px;
}
</style>
