<template>
    <tr v-if="issue" class="issue-row" @click="openTask(issue)">
        <td class="">{{ num }}.</td>
        <td @click.stop="deleteIssueUtil" v-if="isAdmin && !deletedMode">
            <i v-if="!deleting" class="fas fa-trash" style="color: red"></i>
            <i v-else class="fas fa-ellipsis"></i>
        </td>
        <td class="" style="width: 71px">{{ localizeDate(issue.startDate) }}</td>
        <td class="" style="width: 204px">
            {{ stripText(issue.name, 50) }}
        </td>
        <td class="">
            <UserHoverDetail :user="issue.reporterId" />
        </td>
        <!-- <td>
            {{ allProjects.find((item) => item._id == issue.projectId)?.name }}
        </td>
        <td>
            {{ issue.momId && issue.momId.name }}
        </td> -->
        <td style="max-width: 100px">
            <UserHoverDetailList :users="issue.assigneeUserIds" />
        </td>
        <td style="max-width: 100px" v-if="!deletedMode">
            <UserHoverDetailList :users="issue.consultToIds" />
        </td>
        <td style="max-width: 100px" v-if="!deletedMode">
            <UserHoverDetailList :users="issue.informToIds" />
        </td>
        <td>
            <div v-if="issue.statusId" :style="`color: ${issue.statusId.color || 'black'}`">
                {{ issue.statusId.name }}
            </div>
        </td>
        <td style="max-width: 100px" v-if="!deletedMode">
            {{ issue.departmentIds && issue.departmentIds.map((item) => item.name).join(", ") }}
        </td>
        <td>
            <span :style="`color: ${getPriorityColor(issue.priorityId.name)}`">
                {{ issue.priorityId.name }}
            </span>
        </td>
        <!-- <td>{{ localizeDate(issue.startDate) }}</td> -->
        <!-- <td v-if="!deletedMode">{{ localizeDate(issue.interimDate) }}</td> -->
        <td v-if="!deletedMode">{{ localizeDate(issue.endDate) }}</td>
        <td v-if="deletedMode">{{ localizeDate(issue.deletedAt) }}</td>
    </tr>
</template>

<script>
import { mapState, mapActions } from "vuex"
import { hasIssuePermission, localizeDate, stripText } from "@/utils/common"
import UserHoverDetailList from "./UserHoverDetailList.vue"
import UserHoverDetail from "./UserHoverDetail.vue"

export default {
    data() {
        return {
            // not useful any more
            isUserAssignedForIssue: false,
            deleting: false,
        }
    },
    props: {
        issue: { type: Object, default: () => null },
        num: { type: [String, Number], default: () => "" },
        deletedMode: { type: Boolean, default: false },
    },
    computed: {
        ...mapState("auth", ["user", "isAdmin"]),
        ...mapState("project", ["allProjects"]),
    },
    mounted() {
        this.isUserAssignedForIssue = hasIssuePermission(this.user._id, this.issue.assigneeUserIds, this.issue.reporterId)
    },
    components: { UserHoverDetail, UserHoverDetailList },
    methods: {
        ...mapActions("project", ["deleteIssue"]),
        localizeDate,
        stripText,
        openTask(issue) {
            if (!this.deletedMode) {
                window.open("/tasks/" + issue._id, "_blank")
            }
        },
        async deleteIssueUtil() {
            if (this.deleting) return
            if (!confirm("Are you sure?")) return
            this.deleting = true
            try {
                await this.deleteIssue(this.issue._id)
                this.$toast.success("Issue deleted successfully")
            } catch (err) {
                console.log("error in deleting issue", err)
            }
            this.deleting = false
        },
        getPriorityColor(priority) {
            switch (priority.toLowerCase()) {
                case "high":
                case "highest":
                case "medium":
                    return "red"
                case "lowest":
                case "low":
                    return "green"
            }
        },
        getPriorityDirection(priority) {
            switch (priority.toLowerCase()) {
                case "high":
                case "highest":
                case "medium":
                    return "up"
                case "lowest":
                case "low":
                    return "down"
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.issue-row {
    td {
        border: 1px solid #eee;
        padding: 0 5px;
    }
    td *:not(a) {
        border: none;
    }
}
.issue-row {
    &:hover {
        background-color: #f7f7f7;
        cursor: pointer;
    }
}
</style>
