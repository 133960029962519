<template>
    <PageWrapper title="Deleted Tasks" @search="search = $event || ''" :breadCrumbs="[{ title: 'Deleted Tasks', active: true }]">
        <template #header-options> </template>
        <template>
            <div v-if="!loading">
                <table class="issue-table table table-bordered table-striped" v-if="deletedTasks.length">
                    <tr>
                        <th class="py-2">Sl.</th>
                        <th>Start Date</th>
                        <th>Task</th>
                        <!-- <th>Project</th>
                        <th>Mom</th> -->
                        <th>Resp. Person</th>
                        <th>Accountable Person</th>
                        <!-- <th>Creator</th> -->
                        <th>Status</th>
                        <th>Priority</th>
                        <!-- <th>Start Date</th> -->
                        <!-- <th>Interim Date</th>
                        <th>Target Date</th> -->
                        <th>Deleted On</th>
                    </tr>
                    <IssueRow
                        deletedMode
                        :issue="deletedTask"
                        :key="deletedTask._id"
                        v-for="(deletedTask, i) of filteredDeletedTasks"
                        :num="i + 1"
                    />
                </table>
                <div v-else>No Deleted Tasks Found</div>
            </div>

            <!-- <div v-if="activeProject">
                <div class="mb-3">
                    <h4>Project: {{ activeProject.name }}</h4>
                </div>
                <div v-if="activeStatus">
                    <div class="mb-3">
                        <strong>{{ activeStatus.name }}</strong>
                    </div>
                    <div class="row gx-2">
                   
                        <div
                            class="col-md-3"
                            v-for="issue of filterIssues(null, activeStatus.slug)"
                            :key="issue._id"
                        >
                            <IssueCard :issue="issue" />
                            
                        </div>
                        <div v-if="!filterIssues(null, activeStatus.slug).length">
                            No Tasks Found
                        </div>
                    </div>
                </div>
                <div class="row gx-2" v-else>
                    <div
                        class="col-md-3"
                        v-for="status of issueStatuses.filter(
                            (item) => filterIssues(item._id).length
                        )"
                        :key="status._id"
                    >
                        <div class="issue-status">
                            <div class="issue-status-info">{{ status.name }} - {{ filterIssues(status._id).length }}</div>
                            <IssueCard
                                :issue="issue"
                                :key="issue._id"
                                v-for="issue of filterIssues(status._id)"
                            />
                        </div>
                    </div>
                </div>
                <div class="my-2" v-if="!activeProjectIssues || !activeProjectIssues.length">
                    No Tasks Found
                </div>
            </div> -->
            <div v-else>Loading...</div>
        </template>
    </PageWrapper>
</template>

<script>
import { mapState, mapActions } from "vuex"
import PageWrapper from "@/components/layout/PageWrapper.vue"
import IssueFilters from "@/components/other/IssueFilters.vue"
// import IssueCard from "@/components/card/IssueCard.vue"
import IssueRow from "@/components/card/IssueRow.vue"
import { http } from "../../utils/http"

export default {
    computed: {
        ...mapState("auth", ["user", "isAdmin"]),
        ...mapState("project", [""]),
        filteredDeletedTasks() {
            return this.deletedTasks.filter((item) => item.name.toLowerCase().includes(this.search.toLowerCase()))
        },
    },
    components: { PageWrapper, IssueRow, IssueFilters },
    methods: {
        ...mapActions("project", ["initIssues"]),
    },
    data() {
        return {
            search: "",
            loading: false,
            deletedTasks: [],
        }
    },
    async mounted() {
        console.log("mounted running of new project")
        await this.fetchDeletedTasks()
    },
    methods: {
        async fetchDeletedTasks() {
            this.loading = true
            try {
                const res = await http.get("/issue?deleted=true")
                this.deletedTasks = res.data.result
            } catch (err) {
                console.log("error in fetching deleted tasks", err)
            }
            this.loading = false
        },
    },
}
</script>

<style lang="scss" scoped>
.issue-status {
    background-color: #eee;
    padding: 15px;
    height: 100%;
}
.issue-status-info {
    color: #667;
    text-transform: uppercase;
    font-size: 0.9em;
    margin-bottom: 15px;
}
.issue-table {
    font-size: 0.9em;
}
.issue-table th {
    border: 1px solid #eee;
    padding: 0 5px;
}
</style>
