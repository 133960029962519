<template>
    <div>
        <PageWrapper :showHeaderSearch="false" title="My Profile" :breadCrumbs="[{ title: 'Edit Profile', active: true }]">
            <template #header-options> </template>
            <div class="row" v-if="user">
                <div class="col-lg-12 mb-4 mb-sm-5">
                    <div class="card card-style1 border-0">
                        <div class="card-body p-1-9 p-sm-2-3 p-md-6 p-lg-7">
                            <div class="row align-items-center">
                                <div class="col-lg-5 mb-4 mb-lg-0">
                                    <img
                                        class="w-full img-fluid"
                                        style="object-fit: cover; width: 300px; height: 300px; border-radius: 50%"
                                        :src="
                                            !!user.profilePic && !user.profilePic.includes('not-found')
                                                ? `${serverURL}${user.profilePic}`
                                                : require('@/assets/user-icon.jpg')
                                        "
                                        alt="..."
                                    />
                                    <div v-if="editMode">
                                        <div>
                                            <div class="my-3">Update Profile Image:</div>
                                            <div>
                                                <input
                                                    @click="profilePic = null"
                                                    @input="profilePic = $event.target.files[0]"
                                                    type="file"
                                                    accept="image/*"
                                                    class="form-control form-control-sm"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-7 px-xl-10">
                                    <div v-if="!editMode" class="bg-secondary d-lg-inline-block py-1-9 px-1-9 px-sm-6 mb-1-9 rounded">
                                        <h3 class="h3 text-white mb-0">{{ user.firstName }} {{ user.lastName }}</h3>
                                        <span class="text-primary">{{ user.positionName ? user.positionName : "" }}</span>
                                    </div>
                                    <div v-else>
                                        <ul class="list-unstyled mb-1-9">
                                            <li class="mb-2 mb-xl-3 display-28">
                                                <span class="display-26 text-secondary me-2 font-weight-600"
                                                    >First Name<span v-if="editMode">*</span>:</span
                                                >
                                                <span v-if="!editMode">
                                                    {{ user.firstName ? user.firstName : "NA" }}
                                                </span>
                                                <div class="my-1" v-else>
                                                    <CustomInput v-model="firstName" placeholder="John" type="text" />
                                                </div>
                                            </li>
                                            <li class="mb-2 mb-xl-3 display-28">
                                                <span class="display-26 text-secondary me-2 font-weight-600">Last Name:</span>
                                                <span v-if="!editMode">
                                                    {{ user.lastName ? user.lastName : "NA" }}
                                                </span>
                                                <div class="my-1" v-else>
                                                    <CustomInput v-model="lastName" placeholder="Doe" type="text" />
                                                </div>
                                            </li>
                                            <!-- <li class="mb-2 mb-xl-3 display-28">
                                                <span class="display-26 text-secondary me-2 font-weight-600">Password:</span>

                                                <div class="my-1">
                                                    <CustomInput placeholder="Doe" type="password" />
                                                </div>
                                            </li> -->
                                        </ul>
                                    </div>

                                    <ul class="list-unstyled mb-1-9">
                                        <li class="mb-2 mb-xl-3 display-28">
                                            <span class="display-26 text-secondary me-2 font-weight-600">Position:</span>
                                            <span v-if="!editMode">
                                                {{ user.positionName ? user.positionName : "NA" }}
                                            </span>
                                            <div class="my-1" v-else>
                                                <CustomInput v-model="positionName" placeholder="Coach" type="text" />
                                            </div>
                                        </li>
                                        <li class="mb-2 mb-xl-3 display-28">
                                            <span class="display-26 text-secondary me-2 font-weight-600">Experience:</span>
                                            <span v-if="!editMode">
                                                {{ !user.experienceInYears ? "NA" : `${user.experienceInYears} Years` }}
                                            </span>
                                            <div class="my-1" v-else>
                                                (In Years) <CustomInput v-model="experienceInYears" placeholder="5" type="number" />
                                            </div>
                                        </li>
                                        <li class="mb-2 mb-xl-3 display-28">
                                            <span class="display-26 text-secondary me-2 font-weight-600"
                                                >Email<span v-if="editMode">*</span>:</span
                                            >
                                            <span v-if="!editMode">{{ user.email || "NA" }} </span>
                                            <div class="my-1" v-else>
                                                <CustomInput v-model="email" placeholder="abc@test.com" type="email" />
                                            </div>
                                        </li>
                                        <li class="display-28">
                                            <span class="display-26 text-secondary me-2 font-weight-600"
                                                >Phone<span v-if="editMode">*</span>:</span
                                            >
                                            <span v-if="!editMode">{{ user.mobile || "NA" }} </span>
                                            <div class="my-1" v-else>
                                                <CustomInput v-model="mobile" placeholder="1234512345" type="number" />
                                            </div>
                                        </li>
                                    </ul>
                                    <div class="mb-3 d-flex" style="gap: 10px">
                                        <AppBtn :loading="savingUser" :icon="!editMode ? 'edit' : 'check'" @click="handleProfileUpdate">
                                            {{ editMode ? "Save All Changes" : "Update Profile" }}
                                        </AppBtn>
                                        <AppBtn v-if="editMode" @click="handleCancel" style="background-color: crimson"> Cancel </AppBtn>
                                    </div>
                                    <ul class="social-icon-style1 list-unstyled mb-0 ps-0">
                                        <li>
                                            <a href="#!"><i class="ti-twitter-alt"></i></a>
                                        </li>
                                        <li>
                                            <a href="#!"><i class="ti-facebook"></i></a>
                                        </li>
                                        <li>
                                            <a href="#!"><i class="ti-pinterest"></i></a>
                                        </li>
                                        <li>
                                            <a href="#!"><i class="ti-instagram"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 mb-4 mb-sm-5">
                    <div>
                        <span class="section-title text-primary mb-3 mb-sm-4">About Me</span>
                        <p v-if="!editMode">
                            {{ user.aboutInfo || "No Details" }}
                        </p>
                        <div class="my-1" v-else>
                            <CustomInput v-model="aboutInfo" placeholder="Enter your bio..." type="textarea" />
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-lg-12 mb-4 mb-sm-5">
                            <div class="mb-4 mb-sm-5" v-if="skills.length > 0">
                                <span class="section-title text-primary mb-3 mb-sm-4">Skills</span>

                                <div v-if="editMode">
                                    <div v-for="(skill, i) of skillsAdded" :key="skill.skillId">
                                        <div class="skill-edit-box d-flex justify-content-between" style="gap: 15px">
                                            <div class="d-flex" style="gap: 20px">
                                                <div>
                                                    <div class="text-secondary skill-num">Skill #{{ i + 1 }}</div>
                                                    <div class="my-1">
                                                        <SelectDropdown
                                                            :id="`skill-dropdown-${skillsAdded[i].skillId}`"
                                                            style="width: 300px"
                                                            :options="skillOptions"
                                                            :value="skillsAdded[i].skillId"
                                                            @change="handleSkillUpdate(i, $event)"
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div class="skill-num">Skill Level: {{ skillsAdded[i].percentage }}%</div>
                                                    <input
                                                        style="width: 200px"
                                                        class="form-range"
                                                        v-model="skillsAdded[i].percentage"
                                                        min="0"
                                                        max="100"
                                                        type="range"
                                                    />
                                                </div>
                                            </div>
                                            <div class="">
                                                <AppBtn @click="removeSkill(i)" style="background: crimson">
                                                    <i class="fas fa-trash" />
                                                    Remove Skill
                                                </AppBtn>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="skillsAdded.length == 0">No Skills Added</div>
                                    <div class="my-3">
                                        <AppBtn @click="addSkill">
                                            <i class="fas fa-add" />
                                            Add Skill
                                        </AppBtn>
                                    </div>
                                </div>

                                <div v-else>
                                    <div v-for="skill of user.skills || []" :key="skill.skillId && skill.skillId.id">
                                        <div class="progress-text">
                                            <div class="row">
                                                <div class="col-6 mb-1">{{ skill.skillId.name }}</div>
                                                <div class="col-6 text-end">{{ skill.percentage }}%</div>
                                            </div>
                                        </div>
                                        <div class="custom-progress progress progress-medium mb-3" style="height: 4px">
                                            <div
                                                class="animated custom-bar progress-bar slideInLeft bg-secondary"
                                                :style="`width: ${skill.percentage || 0}%`"
                                                aria-valuemax="100"
                                                aria-valuemin="0"
                                                :aria-valuenow="skill.percentage || 0"
                                                role="progressbar"
                                            ></div>
                                        </div>
                                    </div>
                                    <div v-if="!user.skills || user.skills.length == 0">No Skills Added</div>
                                </div>
                            </div>
                            <div>
                                <span class="section-title text-primary mb-3 mb-sm-4">Education</span>
                                <p v-if="!editMode">
                                    {{ user.educationInfo || "No Details" }}
                                </p>
                                <div class="my-1" v-else>
                                    <CustomInput v-model="educationInfo" placeholder="Enter description..." type="textarea" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PageWrapper>
    </div>
</template>

<script>
import Lists from "@/components/Lists/Lists.vue"
import PageWrapper from "@/components/layout/PageWrapper.vue"
import CrudIndex from "@/components/crud/index"
import { http, serverURL } from "@/utils/http"
import { mapState, mapActions } from "vuex"
import { createField, createColumn } from "@/components/crud/utils"
import HeaderSearch from "../components/UI/HeaderSearch.vue"
import AppBtn from "@/components/UI/AppBtn.vue"
import CustomInput from "@/components/other/CustomInput.vue"
import SelectDropdown from "@/components/other/SelectDropdown.vue"
import { errMsg } from "@/utils/common"

export default {
    computed: {
        ...mapState("auth", ["user"]),
        skillOptions() {
            return this.skills.map((item) => ({
                _id: item._id,
                title: item.name,
            }))
        },
    },
    components: { CustomInput, SelectDropdown, HeaderSearch, Lists, PageWrapper, CrudIndex, AppBtn },
    data() {
        return {
            serverURL,
            loading: false,
            editMode: false,
            skills: [],
            savingUser: false,

            password: "",
            // fields allowed to update
            skillsAdded: [],

            firstName: "",
            lastName: "",
            positionName: "",
            experienceInYears: "",
            aboutInfo: "",
            educationInfo: "",
            profilePic: null,
            email: "",
            mobile: "",
        }
    },
    async mounted() {
        this.loading = false
        this.fetchSkills()
    },
    methods: {
        ...mapActions("auth", ["initAuth"]),
        handleCancel() {
            if (confirm("Are you sure to discard changes?")) {
                this.editMode = false
            }
        },
        editingFormatter(item) {
            return {
                ...item,
            }
        },
        async updateUser() {
            if (this.savingUser) return
            try {
                // validate data
                this.skillsAdded = this.skillsAdded.filter((item) => !!item.skillId)
                const skillIdsToUpdate = []
                for (let skill of this.skillsAdded) {
                    if (!skillIdsToUpdate.includes(skill.skillId)) {
                        skillIdsToUpdate.push(skill.skillId)
                    } else {
                        this.$toast.error(`Same skills cannot be added more than once!`)
                        return
                    }
                }
                if (!this.email || !this.mobile || !this.firstName) {
                    this.$toast.error(`Please fill up mandatory fields (star marked)*!`)
                    return
                }

                this.savingUser = true
                const formData = new FormData()
                formData.append("skillsJson", JSON.stringify(this.skillsAdded))

                formData.append("firstName", this.firstName)
                formData.append("lastName", this.lastName)
                formData.append("positionName", this.positionName)
                formData.append("experienceInYears", this.experienceInYears)
                formData.append("aboutInfo", this.aboutInfo)
                formData.append("educationInfo", this.educationInfo)
                formData.append("profilePic", this.profilePic)
                formData.append("mobile", this.mobile)
                formData.append("email", this.email)

                const res = await http.patch(`/users/profile/${this.user._id}`, formData)
                const result = res.data.result

                this.$toast.success(res.data.message)
                await this.initAuth()
                this.editMode = false
            } catch (err) {
                console.log("error in updating user", err)
                this.$toast.error(errMsg(err, "Could not update user!"))
            }
            this.savingUser = false
        },
        addSkill() {
            this.skillsAdded.push({
                skillId: "",
                percentage: 50,
            })
        },
        handleSkillUpdate(index, $event) {
            this.skillsAdded[index].skillId = $event
        },
        async fetchUserIds() {
            let result = []
            try {
                const res = await http.get("/users")
                result = res.data.result
            } catch (err) {
                console.log("error in loading customers", err)
            }
            this.initialized = true
            return result
        },
        async handleProfileUpdate() {
            if (!this.editMode) {
                this.skillsAdded =
                    this.user.skills.slice().map((item) => ({ skillId: item.skillId._id, percentage: item.percentage })) || []

                this.firstName = this.user.firstName || ""
                this.lastName = this.user.lastName || ""
                this.positionName = this.user.positionName || ""
                this.experienceInYears = this.user.experienceInYears || ""
                this.aboutInfo = this.user.aboutInfo || ""
                this.educationInfo = this.user.educationInfo || ""
                this.profilePic = this.user.profilePic || ""
                this.email = this.user.email || ""
                this.mobile = this.user.mobile || ""

                this.editMode = true
            } else {
                // update profile
                this.updateUser()
            }
        },
        removeSkill(index) {
            this.skillsAdded = this.skillsAdded.filter((item, i) => i != index)
        },
        async fetchSkills() {
            try {
                const skillRes = await http.get("/skills")
                this.skills = skillRes.data.result
            } catch (err) {
                console.log("fetch skills error", err)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.card-text {
    font-size: 20px;
    font-weight: 600;
}
.issue-toggle-btn {
    padding: 10px;
    margin-left: 15px;
    border-radius: 5px;
    font-size: 0.9em;
    cursor: pointer;
    background-color: #f8f8f8;
    &:hover {
        background-color: #eee;
    }
}
.card-style1 {
}
.border-0 {
    border: 0 !important;
}
.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
}

.skill-edit-box {
    margin: 14px 0;
    background-color: #eee;
    padding: 15px;
    border-radius: 10px;
}
section {
    padding: 120px 0;
    overflow: hidden;
    background: #fff;
}
.mb-2-3,
.my-2-3 {
    margin-bottom: 2.3rem;
}

.section-title {
    font-weight: 600;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 10px;
    position: relative;
    display: inline-block;
}
.text-primary {
    color: #ceaa4d !important;
}
.text-secondary {
    color: #15395a !important;
}
.font-weight-600 {
    font-weight: 600;
}
.display-26 {
    font-size: 1.3rem;
}

@media screen and (min-width: 992px) {
    .p-lg-7 {
        padding: 4rem;
    }
}
@media screen and (min-width: 768px) {
    .p-md-6 {
        padding: 3.5rem;
    }
}
@media screen and (min-width: 576px) {
    .p-sm-2-3 {
        padding: 2.3rem;
    }
}
.p-1-9 {
    padding: 1.9rem;
}

.bg-secondary {
    background: #15395a !important;
}
@media screen and (min-width: 576px) {
    .pe-sm-6,
    .px-sm-6 {
        padding-right: 3.5rem;
    }
}
@media screen and (min-width: 576px) {
    .ps-sm-6,
    .px-sm-6 {
        padding-left: 3.5rem;
    }
}
.pe-1-9,
.px-1-9 {
    padding-right: 1.9rem;
}
.ps-1-9,
.px-1-9 {
    padding-left: 1.9rem;
}
.pb-1-9,
.py-1-9 {
    padding-bottom: 1.9rem;
}
.pt-1-9,
.py-1-9 {
    padding-top: 1.9rem;
}
.mb-1-9,
.my-1-9 {
    margin-bottom: 1.9rem;
}
@media (min-width: 992px) {
    .d-lg-inline-block {
        display: inline-block !important;
    }
}
.rounded {
    border-radius: 0.25rem !important;
}
.skill-num {
    font-weight: bold;
    color: #333;
}
</style>
