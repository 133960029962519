<template>
    <PageWrapper :showHeaderSearch="false" title="Users" :breadCrumbs="[{ title: 'Users', active: true }]">
        <template #header-options> </template>
        <template>
            <div class="mt-3">
                <CrudIndex
                    v-if="!loading"
                    title="User"
                    :viewColumns="columns"
                    fetchUrl="/users"
                    createUrl="/users"
                    updateRootUrl="/users"
                    deleteRootUrl="/users"
                    :createFields="createFields"
                    :editingFormatter="editingFormatter"
                />
            </div>
        </template>
    </PageWrapper>
</template>

<script>
import Lists from "@/components/Lists/Lists.vue"
import PageWrapper from "@/components/layout/PageWrapper.vue"
import CrudIndex from "@/components/crud/index"
import { createField, createColumn } from "@/components/crud/utils"
import { http } from "@/utils/http"

export default {
    components: { Lists, PageWrapper, CrudIndex },
    data() {
        return {
            customers: {},
            products: {},
            orders: {},
            loading: false,
            columns: [
                createColumn("Id", "index", "string"),
                createColumn("First Name", "firstName", "string"),
                createColumn("Email", "email", "string"),
                createColumn("Created At", "createdAtFormatted2", "string"),
            ],
            createFields: [],
        }
    },
    async mounted() {
        const roles = await this.fetchRoles()
        const departments = await this.fetchDepartments()
        this.createFields = [
            createField("First Name*", "firstName", "text", true),
            createField("Last Name*", "lastName", "text", true),
            createField("Mobile Number", "mobile", "number", false),
            createField("Email Address*", "email", "text", true),
            createField(
                "Password(Only enter if new user or to change to new password. To keep same old password don't enter anything!)",
                "password",
                "text",
                false
            ),
            createField(
                "Role*",
                "roleId",
                "select",
                true,
                "",
                roles.map((item) => ({
                    _id: item._id,
                    title: item.name,
                }))
            ),
            createField(
                "Departments*",
                "departmentIds",
                "multiselect",
                true,
                "",
                departments.map((item) => ({
                    _id: item._id,
                    title: item.name,
                }))
            ),
        ]
        this.loading = false
    },
    methods: {
        editingFormatter(item) {
            return {
                ...item,
            }
        },
        async fetchRoles() {
            let result = []
            try {
                const res = await http.get("/user-roles")
                result = res.data.result
            } catch (err) {
                console.log("error in loading customers", err)
            }
            this.initialized = true
            return result
        },
        async fetchDepartments() {
            let result = []
            try {
                const res = await http.get("/departments")
                result = res.data.result
            } catch (err) {
                console.log("error in loading departments", err)
            }
            this.initialized = true
            return result
        },
    },
}
</script>

<style lang="scss" scoped>
.card-text {
    font-size: 20px;
    font-weight: 600;
}
.issue-toggle-btn {
    padding: 10px;
    margin-left: 15px;
    border-radius: 5px;
    font-size: 0.9em;
    cursor: pointer;
    background-color: #f8f8f8;
    &:hover {
        background-color: #eee;
    }
}
</style>
