<template>
    <div v-if="isUserAssignForProjects">
        <div :class="['project-view', { 'normal-mode': !companyMode }]" :style="`background-color: ${colors[index % colors.length]}`">
            <div
                v-if="companyMode"
                :style="`background-size: cover;  background-image: url('${
                    companyData.thumbnail ? serverURL + companyData.thumbnail : '/banners/company_banner.png'
                }'); height: 150px`"
            ></div>
            <div class="p-3">
                <div class="d-flex justify-content-between">
                    <div class="project-title">
                        {{ companyData.name }}
                    </div>
                    <div class="mt-1">
                        <router-link class="view-link" :to="link">
                            <AppBtn :style="`background-color: ${companyData.btnColor || btnColors[index % btnColors.length]}`"
                                >View</AppBtn
                            ></router-link
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// import { mapState } from "vuex"
import AppBtn from "@/components/UI/AppBtn"
import { mapState } from "vuex"
import { hasIssuePermission } from "@/utils/common"
import { serverURL } from "@/utils/http"

export default {
    components: { AppBtn },
    computed: {
        ...mapState("auth", ["user", "isAdmin"]),
        ...mapState("project", ["activeCompany"]),
    },
    data() {
        return {
            btnColors: ["#379AE6", "#FA5555", "#FA55A5", "#F9623E"],
            colors: ["#F1F8FE", "#FEF3F3", "#FEF0F8", "#FEF3F0"],
            isUserAssignForProjects: false,
            serverURL,
        }
    },
    props: {
        index: { type: Number, default: 0 },
        companyMode: { type: Boolean, default: false },
        link: { type: String, default: "" },
        companyData: { type: Object, default: () => null },
    },
    mounted() {
        this.isUserAssignForProjects = this.isAdmin

        if (!this.isUserAssignForProjects && (this.companyData || this.activeCompany)) {
            this.isUserAssignForProjects = hasIssuePermission(
                this.user._id,
                this.activeCompany ? this.activeCompany.userIds : this.companyData.userIds
            )
        }
    },
}
</script>
<style lang="scss" scoped>
.card-text {
    font-size: 20px;
    font-weight: 600;
}
.issue-toggle-btn {
    padding: 10px;
    margin-left: 15px;
    border-radius: 5px;
    font-size: 0.9em;
    cursor: pointer;
    background-color: #f8f8f8;
    &:hover {
        background-color: #eee;
    }
}
.project-view {
    min-height: 90px;
    background-color: #eee;
    color: rgb(1, 1, 1);
    border-radius: 12px 17px 0 0;
    overflow: hidden;

    &.normal-mode {
        border-radius: 10px;
    }
}

.view-link {
    // margin: 10px 0px;
    // padding: 5px 25px;
    // display: inline-block;
    // border-radius: 5px;
    // background-color: white;
    // border: 1px solid #ccc;
    // &:hover {
    //     border-color: #888;
    //     background-color: rgb(224, 224, 224);
    // }
}
.project-banner-img {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.project-title {
    font-size: 1.2em;
    font-weight: 500;
    margin: 0;
}
</style>
