<template>
    <div class="container">
        <div class="row">
            <div class="pr-6 py-8 pl-10 h-full w-full flex flex-col">
                <div
                    :class="[
                        'd-flex align-items-center',
                        { 'justify-content-between': showTitleOnHead },
                        { 'justify-content-end': !showTitleOnHead },
                    ]"
                    v-if="showHeaderSearch"
                    style="gap: 15px"
                >
                    <div v-if="showTitleOnHead">
                        <div class="text-2xl font-medium">{{ title }}</div>
                    </div>
                    <div class="d-flex align-items-center" style="gap: 15px">
                        <HeaderSearch @change="$emit('search', $event)" />
                        <router-link to="/profile">
                            <img
                                class="shadow-sm"
                                :src="
                                    !!user.profilePic && !user.profilePic.includes('not-found')
                                        ? serverURL + user.profilePic
                                        : require('@/assets/user-icon.jpg')
                                "
                                style="width: 35px; height: 35px; border-radius: 50%"
                            />
                        </router-link>
                    </div>
                </div>
                <nav aria-label="breadcrumb" v-if="breadCrumbs.length">
                    <ol class="breadcrumb mb-1">
                        <li
                            :class="['breadcrumb-item', { active: breadCrumb.active }]"
                            v-for="breadCrumb of breadCrumbs"
                            :key="breadCrumb.link"
                        >
                            <a :href="breadCrumb.link || '#'">{{ breadCrumb.title }}</a>
                        </li>
                    </ol>
                </nav>
                <header class="mt-1 d-flex justify-between text-textDarkest" v-if="!showTitleOnHead && title">
                    <div class="d-flex" style="gap: 25px">
                        <div class="text-2xl font-medium">{{ title }}</div>
                        <slot name="header-options-left" />
                    </div>
                    <div class="d-flex">
                        <slot name="header-options" />
                    </div>
                </header>
                <div class="my-3">
                    <slot />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { serverURL } from "@/utils/http"
import HeaderSearch from "../UI/HeaderSearch.vue"
import { mapState } from "vuex"

export default {
    emits: ["search"],
    computed: {
        ...mapState("auth", ["user"]),
    },
    data() {
        return {
            serverURL,
        }
    },
    props: {
        showTitleOnHead: {
            type: Boolean,
            default: false,
        },
        showHeaderSearch: { type: Boolean, default: true },
        breadCrumbs: { type: Array, default: [] },
        title: { type: String, default: "" },
    },
    components: { HeaderSearch },
}
</script>

<style>
.title {
    font-weight: bold;
    font-size: 1.4rem;
    color: #000;
    margin-bottom: 5px;
}
.card-body {
    flex: 1 1 auto;
    padding: 1rem 1rem;
    /* border: 2px solid #588c9dd1; */
    border-radius: 20px;
}
</style>
