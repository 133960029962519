<template>
    <PageWrapper
        :show-header-search="false"
        title=""
        :breadCrumbs="[
            { title: 'Projects', active: false },
            {
                title: !issue ? 'Project' : issue && issue.projectId.name,
                active: false,
                link: issue && '/projects/' + issue.projectId.slug,
            },
            { title: !issue ? 'Task' : issue && issue.name, active: true },
        ]"
    >
        <template #header-options> </template>
        <template>
            <div class="" v-if="!loading && issue && isUserAssignedForIssue">
                <div class="row">
                    <div class="col-md-8">
                        <div class="mb-4" v-if="issue">
                            <h3 class="h5">{{ issue.name }}</h3>
                        </div>
                        <div>
                            <div class="my-2">
                                <b>Description</b>
                            </div>
                            <div>
                                <div class="card">
                                    <div class="card-body" v-html="description" v-if="isEditDisabled || !!striptags(originalDescription)" />
                                    <HtmlEditor
                                        v-else
                                        :id="`${issue.name.replaceAll(' ', '_').slice(0, 20)}`"
                                        @change="description = $event"
                                        :value="description"
                                    />
                                </div>
                                <div>
                                    <div class="my-2">Accountable person <i class="fa-solid fa-person-circle-check"></i></div>
                                    <SelectDropdown
                                        :id="`issue-assignees`"
                                        :options="
                                            issueCreateOptions.assignees.map((item) => ({
                                                _id: item._id,
                                                title: item.firstName,
                                            }))
                                        "
                                        :multiple="true"
                                        :field="'issue-assignees'"
                                        :value="assigneeUserIds"
                                        :disabled="isEditDisabled"
                                        @change="assigneeUserIds = $event"
                                    />
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="my-2">Consult To <i class="fa-solid fa-microphone"></i></div>
                                        <SelectDropdown
                                            :id="`issue-consult-to`"
                                            :options="
                                                issueCreateOptions.assignees.map((item) => ({
                                                    _id: item._id,
                                                    title: item.firstName,
                                                }))
                                            "
                                            :multiple="true"
                                            :field="'issue-consult-to'"
                                            :value="consultToIds"
                                            :disabled="isEditDisabled"
                                            @change="consultToIds = $event"
                                        />
                                    </div>

                                    <div class="col-6">
                                        <div class="my-2">Inform To <i class="fa-solid fa-volume-high"></i></div>
                                        <SelectDropdown
                                            :id="`issue-inform-to`"
                                            :options="
                                                issueCreateOptions.assignees.map((item) => ({
                                                    _id: item._id,
                                                    title: item.firstName,
                                                }))
                                            "
                                            :multiple="true"
                                            :field="'issue-inform-to'"
                                            :value="informToIds"
                                            :disabled="isEditDisabled"
                                            @change="informToIds = $event"
                                        />
                                    </div>
                                </div>

                                <TagUser
                                    v-if="issue && !isEditDisabled"
                                    :updateRoute="'/issue/' + issue._id"
                                    :taggedUsers="issue.taggedUsers || []"
                                    @update="updateTaggedUsers"
                                />

                                <div class="my-2" v-if="showAttachmentManager">Attachments:</div>
                                <div class="attachment-manager" v-if="showAttachmentManager">
                                    <AttachmentUploader
                                        @remove="addToRemoveList('attachment1')"
                                        v-model="attachment1"
                                        :id="1"
                                        :name="attachment1Name"
                                        :isUser="isEditDisabled"
                                    />
                                    <AttachmentUploader
                                        @remove="addToRemoveList('attachment2')"
                                        v-model="attachment2"
                                        :id="2"
                                        :name="attachment2Name"
                                        :isUser="isEditDisabled"
                                    />
                                    <AttachmentUploader
                                        @remove="addToRemoveList('attachment3')"
                                        v-model="attachment3"
                                        :id="3"
                                        :name="attachment3Name"
                                        :isUser="isEditDisabled"
                                    />
                                    <AttachmentUploader
                                        @remove="addToRemoveList('attachment4')"
                                        v-model="attachment4"
                                        :id="4"
                                        :name="attachment4Name"
                                        :isUser="isEditDisabled"
                                    />
                                    <AttachmentUploader
                                        @remove="addToRemoveList('attachment5')"
                                        v-model="attachment5"
                                        :id="5"
                                        :name="attachment5Name"
                                        :isUser="isEditDisabled"
                                    />
                                </div>
                                <div class="my-2" v-if="!isEditDisabled">
                                    <AppBtn @click="saveChanges(true)">{{ savingRestricted ? "Saving..." : "Save" }}</AppBtn>
                                </div>

                                <CommentSystem :issueId="$route.params.id" />
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div style="width: 150px">
                            <SelectDropdown
                                v-if="allowStatusUpdate"
                                :id="`issue-status`"
                                :options="
                                    issueCreateOptions.issueStatuses
                                        .filter(
                                            (item) =>
                                                ![
                                                    // 'to do', 'backlog'
                                                ].includes(item.name.toLowerCase())
                                        )
                                        .map((item) => ({
                                            _id: item._id,
                                            title: item.name,
                                        }))
                                "
                                :multiple="false"
                                :field="'issue-status'"
                                :value="issueStatusId"
                                @change="issueStatusId = $event"
                            />
                            <div v-else>
                                <strong>Task Status: {{ issue.statusId && issue.statusId.name }}</strong>
                            </div>
                        </div>
                        <Accordion :title="'Details'" :defaultShow="true" :subtitle="'Departments, Reporter, Priority'">
                            <ListView title="Departments">
                                <SelectDropdown
                                    :id="`issue-departments`"
                                    :options="
                                        issueCreateOptions.departments.map((item) => ({
                                            _id: item._id,
                                            title: item.name,
                                        }))
                                    "
                                    :multiple="true"
                                    :field="'issue-departments'"
                                    :value="departmentIds"
                                    :disabled="isEditDisabled"
                                    @change="departmentIds = $event"
                            /></ListView>
                            <ListView
                                title="Task Pending Due To"
                                v-if="issueStatusId == issueCreateOptions.issueStatuses.find((item) => item.slug == 'pending')._id"
                            >
                                <SelectDropdown
                                    :id="`issue-pending-task`"
                                    :options="
                                        issueCreateOptions.allUsers.map((item) => ({
                                            _id: item._id,
                                            title: item.firstName,
                                        }))
                                    "
                                    :multiple="true"
                                    :field="'issue-pending-task'"
                                    :value="pendingDueToUserIds"
                                    :disabled="isEditDisabled"
                                    @change="pendingDueToUserIds = $event"
                            /></ListView>
                            <ListView title="Start Date" v-if="startDate">
                                {{ new Date(startDate).toDateString() }}
                            </ListView>
                            <div class="" style="background: #f1f8fd">
                                <ListView title="Target Date" v-if="endDate">
                                    <input
                                        type="date"
                                        :min="new Date(startDate).toISOString().slice(0, 10)"
                                        :value="new Date(endDate).toISOString().slice(0, 10)"
                                        @change="endDate = $event.target.value"
                                        :disabled="isEditDisabled"
                                    />
                                </ListView>
                            </div>
                            <ListView title="Interim Date" v-if="interimDate">
                                <input
                                    type="date"
                                    :max="new Date(endDate).toISOString().slice(0, 10)"
                                    :value="new Date(interimDate).toISOString().slice(0, 10)"
                                    @change="interimDate = $event.target.value"
                                    :disabled="isEditDisabled"
                                />
                            </ListView>
                            <ListView title="Responsible Person">
                                <SelectDropdown
                                    :id="`issue-reporter`"
                                    :options="
                                        issueCreateOptions.allUsers.map((item) => ({
                                            _id: item._id,
                                            title: item.firstName,
                                        }))
                                    "
                                    :multiple="false"
                                    :field="'issue-reporter'"
                                    :value="reporterId"
                                    :disabled="isEditDisabled"
                                    @change="reporterId = $event"
                            /></ListView>

                            <ListView title="Priority">
                                <SelectDropdown
                                    :id="`issue-priority`"
                                    :options="
                                        issueCreateOptions.issuePriorities.map((item) => ({
                                            _id: item._id,
                                            title: item.name,
                                        }))
                                    "
                                    :multiple="false"
                                    :field="'issue-priority'"
                                    :value="priorityId"
                                    :disabled="isEditDisabled"
                                    @change="priorityId = $event"
                                />
                            </ListView>

                            <ListView title="MOM">
                                <SelectDropdown
                                    :id="`mom`"
                                    :options="
                                        issueCreateOptions.moms.map((item) => ({
                                            _id: item._id,
                                            title: item.name,
                                        }))
                                    "
                                    :multiple="false"
                                    :field="'mom'"
                                    :value="momId"
                                    :disabled="isEditDisabled"
                                    @change="momId = $event"
                                />
                            </ListView>
                        </Accordion>
                        <!-- <Accordion
                            :title="'More Fields'"
                            :subtitle="'Original estimate, Time Tracking'"
                        >
                            Coming Soon...
                        </Accordion> -->
                        <div v-if="processingForm"><small>Saving Changes...</small></div>
                    </div>
                </div>
            </div>
            <div v-else-if="loading">Loading Task...</div>
            <div v-else-if="!issue">No Task Found</div>
            <div v-else-if="!isUserAssignedForIssue">You do not have access to this task!</div>
        </template>
    </PageWrapper>
</template>

<script>
import striptags from "striptags"
import PageWrapper from "@/components/layout/PageWrapper.vue"
import HtmlEditor from "@/components/other/HtmlEditor"
import AppBtn from "@/components/UI/AppBtn"
import SelectDropdown from "@/components/other/SelectDropdown"
import CommentSystem from "@/components/Lists/CommentSystem"
import Accordion from "@/components/layout/Accordion"
import ListView from "@/components/layout/ListView"
import TagUser from "@/components/other/TagUser"
import { mapState, mapActions } from "vuex"
import { errMsg, hasIssuePermission } from "@/utils/common"
import AttachmentUploader from "@/components/other/AttachmentUploader"
import { http } from "@/utils/http"

export default {
    components: {
        PageWrapper,
        HtmlEditor,
        AppBtn,
        SelectDropdown,
        Accordion,
        ListView,
        CommentSystem,
        AttachmentUploader,
        TagUser,
    },
    computed: {
        ...mapState("project", ["projects", "issueStatuses"]),
        ...mapState("auth", ["user", "isAdmin"]),
        isCreatorOrAdmin() {
            return this.user._id == this.issue.reporterId._id || this.isAdmin
        },
    },
    data() {
        return {
            showAttachmentManager: true,
            momId: "",
            loading: false,
            issueCreateOptions: null,
            description: "",
            issue: null,
            processingForm: false,
            issueStatusId: "",
            assigneeUserIds: [],
            departmentIds: [],
            reporterId: null,
            priorityId: null,
            savingRestricted: false,
            isEditDisabled: false,
            isUserAssignedForIssue: false,
            attachment1: null,
            attachment2: null,
            attachment3: null,
            attachment4: null,
            attachment5: null,
            attachment1Name: "",
            attachment2Name: "",
            attachment3Name: "",
            attachment4Name: "",
            attachment5Name: "",
            removeFilesList: [],
            startDate: "",
            endDate: "",
            interimDate: "",
            reportToIds: [],
            pendingDueToUserIds: [],
            consultToIds: [],
            informToIds: [],
        }
    },
    async mounted() {
        window.document.title = "MOM | TASK"
        this.updateIssueDetails(true)
    },
    methods: {
        striptags,
        ...mapActions("auth", ["checkRole"]),
        updateTaggedUsers(ticketUpdate) {
            if (ticketUpdate) {
                console.log("ticket update", ticketUpdate)
                this.issue.taggedUsers = ticketUpdate.taggedUsers
            }
        },
        async updateIssueDetails(fetchData) {
            this.loading = true

            if (fetchData) {
                const issueCreateOptions = await this.fetchIssueCreationOptions()
                this.issueCreateOptions = issueCreateOptions
                this.issue = issueCreateOptions.issue
            }

            if (this.issue) {
                const descriptionData = this.issue.description && this.issue.description !== "undefined" ? this.issue.description : ""
                this.description = descriptionData
                this.originalDescription = descriptionData
                this.assigneeUserIds = this.issue.assigneeUserIds.map((item) => item._id)
                this.consultToIds = this.issue.consultToIds.map((item) => item._id)
                this.informToIds = this.issue.informToIds.map((item) => item._id)

                this.departmentIds = this.issue.departmentIds
                this.reporterId = this.issue.reporterId && this.issue.reporterId._id
                this.priorityId = this.issue.priorityId && this.issue.priorityId._id
                this.issueStatusId = this.issue.statusId && this.issue.statusId._id
                this.momId = this.issue.momId && this.issue.momId._id

                this.startDate = this.issue.startDate
                this.endDate = this.issue.endDate
                this.interimDate = this.issue.interimDate
                this.reportToIds = this.issue.reportToIds
                this.pendingDueToUserIds = this.issue.pendingDueToUserIds

                this.attachment1Name = this.issue.attachment1
                this.attachment2Name = this.issue.attachment2
                this.attachment3Name = this.issue.attachment3
                this.attachment4Name = this.issue.attachment4
                this.attachment5Name = this.issue.attachment5
            }

            this.isEditDisabled = await this.checkRole({ user: this.user, roleToCheck: "User" })

            this.allowStatusUpdate =
                this.isAdmin || hasIssuePermission(this.user._id, [], this.issue.reporterId && this.issue.reporterId._id)

            this.isUserAssignedForIssue =
                this.isAdmin ||
                hasIssuePermission(this.user._id, this.issue.assigneeUserIds, this.issue.reporterId && this.issue.reporterId._id)

            this.loading = false
        },
        async fetchIssueCreationOptions() {
            let result = {}
            try {
                const res = await http.get(`/issue/init?issue_id=${this.$route.params.id}`)
                result = res.data
            } catch (err) {
                console.log("error in loading customers", err)
            }
            this.initialized = true
            return result
        },
        async saveChanges(restricted = false) {
            let result = {}
            if (restricted) {
                if (this.savingRestricted) return
                this.savingRestricted = true
            }
            if (this.processingForm) return
            this.processingForm = true
            if (this.attachment1 || this.attachment2 || this.attachment3 || this.attachment4 || this.attachment5) {
                this.showAttachmentManager = false
            }
            try {
                const formData = new FormData()

                formData.append("description", this.description || "")
                formData.append("assigneeUserIds", JSON.stringify(this.assigneeUserIds) || "")
                formData.append("consultToIds", JSON.stringify(this.consultToIds) || "")
                formData.append("informToIds", JSON.stringify(this.informToIds) || "")

                formData.append("departmentIds", JSON.stringify(this.departmentIds) || "")
                formData.append("pendingDueToUserIds", JSON.stringify(this.pendingDueToUserIds) || "")
                formData.append("momId", this.momId)
                formData.append("priorityId", this.priorityId || "")
                formData.append("reporterId", this.reporterId || "")
                formData.append("statusId", this.issueStatusId || "")
                formData.append("endDate", this.endDate || "")
                formData.append("interimDate", this.interimDate || "")

                // files to remove if any
                formData.append("removeFilesList", JSON.stringify(this.removeFilesList))

                // attachments to add if any
                formData.append("attachment1", this.attachment1 || "")
                formData.append("attachment2", this.attachment2 || "")
                formData.append("attachment3", this.attachment3 || "")
                formData.append("attachment4", this.attachment4 || "")
                formData.append("attachment5", this.attachment5 || "")

                const res = await http.patch(`/issue/${this.$route.params.id}`, formData)
                result = res.data.result
                this.issue = result
                await this.updateIssueDetails(false)
                if (restricted) {
                    this.$toast.info("Saved Changes")
                }
            } catch (err) {
                console.log("error in loading customers", err)
                this.$toast.error("Failed to update!")
                this.$toast.error(errMsg(err))
            }
            this.showAttachmentManager = true
            this.savingRestricted = false
            this.processingForm = false
            this.initialized = true
            return result
        },
        async addToRemoveList(item) {
            if (!this.removeFilesList.includes(item)) {
                this.removeFilesList = [...this.removeFilesList, item]
            }
            console.log("remove files list", this.removeFilesList)
        },
    },
    watch: {
        priorityId(e) {
            this.saveChanges()
        },
        issueStatusId(e) {
            this.saveChanges()
        },
        reporterId(e) {
            this.saveChanges()
        },
        assigneeUserIds(e) {
            this.saveChanges()
        },
        departmentIds(e) {
            this.saveChanges()
        },
        pendingDueToUserIds(e) {
            this.saveChanges()
        },
        momId(e) {
            this.saveChanges()
        },
        interimDate(e) {
            this.saveChanges()
        },
        endDate(e) {
            this.saveChanges()
        },
    },
}
</script>

<style lang="scss" scoped>
.card-text {
    font-size: 20px;
    font-weight: 600;
}
.issue-toggle-btn {
    padding: 10px;
    margin-left: 15px;
    border-radius: 5px;
    font-size: 0.9em;
    cursor: pointer;
    background-color: #f8f8f8;
    &:hover {
        background-color: #eee;
    }
}
.attachment-manager {
    display: flex;
    gap: 15px;
    flex-flow: row wrap;
}
</style>
