var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidebar-nav"},[_c('div',{staticClass:"sidebar-blue"},[_c('div',{staticClass:"sidebar-blue-inner"},[_c('div',{staticClass:"mt-3"}),_c('router-link',{staticClass:"mt-3 link-hover-val",attrs:{"to":'/'}},[_c('i',{staticClass:"fa fa-home"}),_vm._v(" "),_c('span',{staticClass:"ms-4"},[_vm._v("Home")])]),_c('a',{staticClass:"mt-3 link-hover-val",attrs:{"href":`/projects/${_vm.allProjects && _vm.allProjects[0] && _vm.allProjects[0].slug}?search_mode=true`}},[_c('i',{staticClass:"fa fa-search"}),_vm._v(" "),_c('span',{staticClass:"ms-4"},[_vm._v("Search")])]),_c('div',[_c('router-link',{staticClass:"mt-3 link-hover-val",attrs:{"to":'/tasks/create'}},[_c('i',{staticClass:"fa fa-plus-circle"}),_vm._v(" "),_c('span',{staticClass:"ms-4"},[_vm._v("New Task")])])],1),_c('div',[_c('router-link',{staticClass:"mt-3 link-hover-val",attrs:{"to":'/tasks/create-bulk'}},[_c('i',{staticClass:"fab fa-bitbucket"}),_vm._v(" "),_c('span',{staticClass:"ms-4"},[_vm._v("Bulk Tasks")])])],1)],1)]),_c('div',{staticClass:"overflow-hidden",staticStyle:{"min-height":"100vh","background-color":"#f4f5f7"}},[_c('div',{staticClass:"container mt-3"},[_c('div',{staticClass:"my-3"},[_c('router-link',{attrs:{"to":"/profile"}},[_c('h4',{staticClass:"sidebar-subtitle flex"},[_c('img',{staticClass:"mr-3 bg-white",staticStyle:{"width":"30px","height":"30px","object-fit":"cover","border-radius":"50%"},attrs:{"src":!!_vm.user.profilePic && !_vm.user.profilePic.includes('not-found')
                                    ? _vm.serverURL + _vm.user.profilePic
                                    : require('@/assets/user-icon.jpg'),"alt":""}}),_vm._v(" "+_vm._s(_vm.user.firstName)+" "+_vm._s(_vm.user.lastName)+" ")])])],1),_c('SidebarLinkItem',{attrs:{"title":"Dashboard","icon":"area-chart","link":'/dashboard'},nativeOn:{"click":function($event){return _vm.clearActiveData.apply(null, arguments)}}}),_c('div',[_c('div',{staticClass:"my-3 py-2 active-project-container"},[_c('h4',{staticClass:"h6"},[_vm._v("Company")]),(_vm.companyInitialized && _vm.showSelect)?_c('SelectDropdown',{attrs:{"id":"company","additionalDelayTrigger":true,"options":_vm.companies.map((item) => ({ _id: item._id, title: item.name })),"field":"company","value":_vm.activeCompany && _vm.activeCompany._id},on:{"change":_vm.handleCompanyChange}}):_vm._e(),(!!_vm.activeCompany)?_c('h4',{staticClass:"h6 mt-2"},[_vm._v("Project")]):_vm._e(),(!!_vm.activeCompany && _vm.showSelect)?_c('div',[_c('SelectDropdown',{attrs:{"id":"project","additionalDelayTrigger":true,"options":_vm.activeCompanyProjects.map((item) => ({
                                    _id: item._id,
                                    title: item.name,
                                })),"field":"project","value":_vm.activeProject && _vm.activeProject._id},on:{"change":_vm.handleProjectChange}})],1):_vm._e()],1),(_vm.activeProject)?_c('SidebarLinkItem',{attrs:{"title":"All","icon":"database","disableActiveClass":true,"link":"","active":_vm.isIssueStatusActive()},nativeOn:{"click":function($event){return _vm.setStatusFilter()}}}):_vm._e(),(_vm.activeProject)?_c('div',_vm._l((_vm.issueStatuses),function(issueStatus){return _c('SidebarLinkItem',{key:issueStatus._id,attrs:{"title":issueStatus.name,"icon":"database","link":"","disableActiveClass":true,"active":_vm.isIssueStatusActive(issueStatus)},nativeOn:{"click":function($event){return _vm.setStatusFilter(issueStatus._id)}}})}),1):_vm._e(),_c('SidebarLinkItem',{attrs:{"title":"Deleted Tasks","icon":"trash","link":"/tasks/deleted"}})],1),(_vm.isAdmin)?_c('div',[_vm._m(0),_c('SidebarLinkItem',{attrs:{"title":"Task Status","icon":"list","link":'/task-status-stats'}}),_c('SidebarLinkItem',{attrs:{"title":"Task Priorities","icon":"list","link":'/task-priorities'}}),_c('SidebarLinkItem',{attrs:{"title":"Departments","icon":"list","link":'/departments'}})],1):_vm._e(),_vm._m(1),(_vm.isAdmin)?_c('div',[_c('SidebarLinkItem',{attrs:{"title":"Companies","icon":"building","link":'/companies'}})],1):_vm._e(),_c('SidebarLinkItem',{attrs:{"title":"Projects","icon":"bar-chart","link":'/projects'}}),(_vm.isAdmin)?_c('div',[_c('SidebarLinkItem',{attrs:{"title":"Skills","icon":"medal","link":'/skills'}}),_c('SidebarLinkItem',{attrs:{"title":"Moms","icon":"wallet","link":'/moms'}}),_c('SidebarLinkItem',{attrs:{"title":"Users","icon":"user","link":'/users'}}),_c('SidebarLinkItem',{attrs:{"title":"User Roles","icon":"user","link":'/user-roles'}})],1):_vm._e(),_c('button',{staticClass:"logout-btn",on:{"click":_vm.logoutUtil}},[_vm._v("Logout "),_c('i',{staticClass:"fas fa-sign-out"})]),_c('div',{staticClass:"my-3"})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-3"},[_c('h4',{staticClass:"sidebar-subtitle"},[_vm._v("Update")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-3"},[_c('h4',{staticClass:"sidebar-subtitle"},[_vm._v("Settings")])])
}]

export { render, staticRenderFns }